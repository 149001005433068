import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Button, Card, CircularProgress, Box,
    Typography,
    Divider
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { experimentalStyled as styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { useGetAddresseDetailsQuery } from '../../redux/services/settings/AddressesService';
import { useGetJobDetailsCareerQuery } from '../../redux/services/jobs/JobServices';

function SingleJobView() {
    const { id } = useParams()
    const location = useLocation();
    const logo = location.state?.logo;
    const company = location.state?.company;
    localStorage.setItem("jobId", id)
    const { data, isLoading, refetch } = useGetJobDetailsCareerQuery(id);    
    const { data: addressData, isLoading: addressIsLoading, refetch: addressRefetch } = useGetAddresseDetailsQuery(data?.data.location);
    const [jobType, setJobType] = useState()
    const navigate = useNavigate()    
    
    const mappingEducation = {
        1: "High School",
        2: "Junior College",
        3: "Bachelors",
        4: "Masters"
    }

    useEffect(() => {
        refetch()
    }, [id])

    useEffect(() => {
        if (data?.data.type === "F") {
            setJobType("Full Time")
        } else {
            setJobType("Part Time")
        }
        // console.log(data?.assesment)
        localStorage.setItem("assesment", data?.data.assesment)
    }, [data])

    return (
        <>
            <Box sx={{ backgroundColor: "#ffffff", minHeight: "100vh", mt: 0, p: 0 }}>
            <Box
                sx={{
                    backgroundImage: `url(${"https://static.zohocdn.com/recruit/images/cover1.0e02dce62a260cd1dbbafeacf59e558a.jpg"})`,
                    backgroundSize: '110%', // Zoom effect
                    backgroundPosition: 'center',
                    color: '#fff',
                    textAlign: 'center',
                    py: 5,
                    // filter: 'blur(1px)', // Blur effect
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Optional overlay for better text contrast
                        zIndex: 1,
                    },
                    '& > *': {
                        position: 'relative',
                        zIndex: 2,
                    }
                }}
            >
                <Typography variant="h5" gutterBottom>
                    {company} | {jobType}
                </Typography>
                <Typography variant="h3">
                    {data?.data.title}
                </Typography>
                <Typography variant="subtitle1">
                    {addressData?.data.city_name}, {addressData?.data.country_name}
                </Typography>
                <Typography variant="caption">
                    Posted on {data?.data.posted_date}
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        sx={{ mr: 1 }}
                        onClick={() => navigate(`/JobApplication/${data.data.webform}`)}
                    >
                        I'm interested
                    </Button>
                    <Button 
                        variant="outlined" 
                        sx={{
                            color: 'white',
                            borderColor: 'white',
                            '&:hover': {
                                borderColor: 'white',
                                backgroundColor: 'rgba(255, 255, 255, 0.1)', // Optional hover effect
                            }
                        }}>
                        Share job via email
                    </Button>
                </Box>
            </Box>
            <Card sx={{ mt: 5, backgroundColor: "#f9fafb", p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid container spacing={2} sx={{ maxWidth: '1200px', width: '100%' }}>
                        <Grid item xs={12} md={7}>
                            <Typography variant="h5" gutterBottom sx={{ color: '#333' }}>
                                Job Description
                            </Typography>
                            <Box
                                sx={{ marginBottom: '1em', color: '#333' }}
                                dangerouslySetInnerHTML={{ __html: data?.data.description }}
                            />
                        </Grid>
                        <Divider orientation="vertical" flexItem sx={{ mx: 2, height: 'auto' }} />
                        <Grid item xs={12} md={4}>
                            <Typography variant="h5" gutterBottom sx={{ color: '#333' }}>
                                Job Information
                            </Typography>
                            <Typography sx={{ mb: 0.5, color: '#333', fontWeight: 'bold' }}>Date Opened:</Typography>
                            <Typography sx={{ mb: 1, color: '#333' }}>{data?.data.created.split('T')[0]}</Typography>
                            
                            <Typography sx={{ mb: 0.5, color: '#333', fontWeight: 'bold' }}>Job Type:</Typography>
                            <Typography sx={{ mb: 1, color: '#333' }}>{jobType}</Typography>
                            
                            <Typography sx={{ mb: 0.5, color: '#333', fontWeight: 'bold' }}>Industry:</Typography>
                            <Typography sx={{ mb: 1, color: '#333' }}>{data?.data.industry}</Typography>
                            
                            <Typography sx={{ mb: 0.5, color: '#333', fontWeight: 'bold' }}>Work Experience:</Typography>
                            <Typography sx={{ mb: 1, color: '#333' }}>{data?.data.exp_min}-{data?.data.exp_max} Years</Typography>
                            
                            <Typography sx={{ mb: 0.5, color: '#333', fontWeight: 'bold' }}>City:</Typography>
                            <Typography sx={{ mb: 1, color: '#333' }}>{addressData?.data.city_name}</Typography>
                            
                            <Typography sx={{ mb: 0.5, color: '#333', fontWeight: 'bold' }}>State/Province:</Typography>
                            <Typography sx={{ mb: 1, color: '#333' }}>{addressData?.data.state_name}</Typography>
                            
                            <Typography sx={{ mb: 0.5, color: '#333', fontWeight: 'bold' }}>Country:</Typography>
                            <Typography sx={{ mb: 1, color: '#333' }}>{addressData?.data.country_name}</Typography>
                            
                            <Typography sx={{ mb: 0.5, color: '#333', fontWeight: 'bold' }}>Zip/Postal Code:</Typography>
                            <Typography sx={{ mb: 1, color: '#333' }}>{addressData?.data.pincode}</Typography>
                            
                            <Typography sx={{ mb: 0.5, color: '#333', fontWeight: 'bold' }}>Number of Positions:</Typography>
                            <Typography sx={{ mb: 1, color: '#333' }}>{data?.data.vacancies}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <Button
                        sx={{
                            mb: 2,
                            // borderRadius: "20px",
                            width: "150px"
                        }}
                        variant="contained"
                        onClick={() => navigate(`/JobApplication/${data.data.webform}`)}
                    >
                        I'm Interested
                    </Button>
                </Box>
            </Card>
            </Box>
        </>
    )
}

export default SingleJobView