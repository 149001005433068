// NavbarMui.js

import React from 'react';
import { AppBar, Toolbar, Typography, Button, TextField, IconButton, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { Link, NavLink } from 'react-router-dom'; // If you're using React Router
import edjobster09 from "../assets/images/edjobster-09.png"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    link: {
        textDecoration: 'none',
        color: 'white',
    },
}));

const Navbar = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" sx={{ background: '#f4f6f8',boxShadow:'none' }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="h6" className={classes.title}>
                        <img
                            style={{ width: "13%", margin: "0" }}
                            src={edjobster09}
                            alt="logo" />
                    </Typography>

                    <Box 
                        className="header-menu-list" 
                        sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: '32px', 
                            flexWrap: 'nowrap',
                            '& a': {
                                textDecoration: 'none',
                                color: 'black', // Ensure links are visible
                                '&.header-job-active': {
                                    fontWeight: 'bold',
                                    color: '#89ba16', // Active link color
                                },
                            },
                        }}
                    >
                        <NavLink to="/dashboard/jobs" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
                            Home
                        </NavLink>
                        <NavLink to="/jobs" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
                            Jobs
                        </NavLink>
                        {/* <NavLink to="/dashboard/interviews" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
                            Interviews
                        </NavLink>
                        <NavLink to="/dashboard/assessments" className={({ isActive }) => isActive ? "header-job-active" : "header-menu"}>
                            Assessments
                        </NavLink> */}
                    </Box>

                    {/* <TextField
                        id="outlined-basic"
                        // onChange={inputHandler}
                        variant="outlined"
                        fullWidth
                        label="Search"
                        InputProps={{
                            endAdornment: (
                                <IconButton  edge="end">
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                        sx={{
                            width:'30%',
                            borderRadius:'8px',
                            '& .MuiInputBase-input.MuiOutlinedInput-input': {
                                padding: '14px 14px',
                            },
                        }}
                    /> */}
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Navbar;
