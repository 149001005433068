import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { apiGET } from '../../utils/apis';



function JobsPage() {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiGET('jobs/latest-jobs/');
                setJobs(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);    

    return (
        <>
            <Box sx={{ backgroundColor: "#ffffff", minHeight: "50vh", mt: 0, p: 0 }}>
                <Box
                    sx={{
                        display: 'flex', // Add flexbox
                        flexDirection: 'column', // Align items vertically
                        justifyContent: 'center', // Center items vertically
                        alignItems: 'center', // Center items horizontally
                        backgroundImage: `url(${"https://static.zohocdn.com/recruit/images/cover1.0e02dce62a260cd1dbbafeacf59e558a.jpg"})`,
                        backgroundSize: '110%', // Zoom effect
                        backgroundPosition: 'center',
                        color: '#fff',
                        textAlign: 'center',
                        py: 5,
                        position: 'relative',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Optional overlay for better text contrast
                            zIndex: 1,
                        },
                        '& > *': {
                            position: 'relative',
                            zIndex: 2,
                            maxWidth: '700px',
                            mx: 'auto',
                        }
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Find the career of your dreams
                    </Typography>
                    <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                        We're more than just a workplace. We're a family.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                        We know that finding a meaningful and rewarding job can be a long journey. Our goal is to make that process as easy as possible for you, and to create a work environment that's satisfying - one where you'll look forward to coming to every day. Start your journey with us by browsing available jobs.
                    </Typography>
                    <Button variant="contained" color="primary">
                        View Openings
                    </Button>
                </Box>
            </Box>

            <Box sx={{ p: 4, textAlign: 'center' }}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Apply Now
                </Typography>
                <Typography variant="h6" sx={{ mb: 4 }}>
                    Current Openings
                </Typography>
                <Grid container spacing={2} justifyContent="center" sx={{ px: 2 }}>
                    {jobs.map((job, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index} sx={{ maxWidth: '300px' }}>
                            <Card
                                key={job.id}
                                variant="outlined"
                                sx={{
                                    transition: 'transform 0.3s, box-shadow 0.3s',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                                    },
                                }}
                            >
                                <CardContent sx={{ textAlign: 'left' }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        {job.type}
                                    </Typography>
                                    <Typography variant="body1" color="primary">
                                        {job.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {job.location},{job.country}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Button variant="outlined" sx={{ mt: 4, borderRadius: '20px' }}>
                    10 MORE
                </Button>
            </Box>
        </>
    )
}

export default JobsPage